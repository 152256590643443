import { Accordion } from "react-bootstrap";
import * as Unicons from '@iconscout/react-unicons';

export function SkillSet() {
    

    return(
  
      <Accordion flush>
      <Accordion.Item eventKey="0" >
        <Accordion.Header><b>HTML</b>  Maestro</Accordion.Header>
        <Accordion.Body>
        A decade-long journey in web development has honed my HTML skills to perfection. I effortlessly craft clean, semantically rich code that forms the backbone of seamless user experiences. From intricate layouts to platform integration, my HTML expertise ensures top-notch web solutions.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header><b>CSS</b> Artisan</Accordion.Header>
        <Accordion.Body>
        Embarking on my web journey during the era of CSS2 and navigating the intricacies of Internet Explorer compatibility, I've evolved into a CSS virtuoso. From the foundations of cross-browser support to today's powerful styling capabilities, my CSS expertise combines legacy wisdom with cutting-edge finesse for visually stunning and universally compatible designs.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><b>jQuery</b> Whisperer</Accordion.Header>
        <Accordion.Body>
        
        A self-taught maestro of jQuery, I delved into this dynamic library when it wasn't the headline of JavaScript frameworks. Today, while it may not be in the spotlight, it quietly orchestrates functionality for over 90% of websites worldwide. My proficiency in jQuery is a nod to foundational skills that withstand the test of time.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><b>ReactJS</b> Explorer</Accordion.Header>
        <Accordion.Body>
        Embarking on a ReactJS journey, I'm not yet a master, but an avid learner thrilled to infuse this cutting-edge framework into projects. Notably, this portfolio is a testament to my early exploits in the React terrain. The journey unfolds, and I'm eager for what lies ahead.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header><b>Strategic</b> UX Navigator</Accordion.Header>
        <Accordion.Body>
        My journey into User Experience (UX) began when it was called with "Usability," and it has evolved into a strategic approach. As a UX consultant and designer, I am not just involved in the aesthetics but embedded in the early project stages, ensuring a seamless marriage of design and functionality. I bring a UX mindset that adds intrinsic value, anticipating challenges before they become roadblocks in development.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header><b>Assistant/Project Management</b> Alchemist</Accordion.Header>
        <Accordion.Body>
        
Bearing no formal title, my journey has seen me master the art of orchestrating projects and teams. I thrive in ensuring every project element aligns seamlessly with the vision, deadlines are met, and the end product surpasses expectations. A silent maestro in the symphony of project management, I weave dedication, technical prowess, and a touch of creativity into every endeavor.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header><b>Client</b> Harmonizer</Accordion.Header>
        <Accordion.Body>
        Navigating the intricate dance between stakeholders, I've made it a mission to harmonize conflicting perspectives toward a common goal. Guiding clients, often unfamiliar with the digital realm, through the intricate journey of understanding their needs and translating them into digital, web, and UX solutions is my forte. I don't just hear client expectations; I decipher and translate them into seamless online experiences.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header><b>Kanban</b> Harmonizer</Accordion.Header>
        <Accordion.Body>
        Navigating the intricate dance between stakeholders, I've made it a mission to harmonize conflicting perspectives toward a common goal. Guiding clients, often unfamiliar with the digital realm, through the intricate journey of understanding their needs and translating them into digital, web, and UX solutions is my forte. I don't just hear client expectations; I decipher and translate them into seamless online experiences.
        </Accordion.Body>
      </Accordion.Item>
      





</Accordion>

    )
}